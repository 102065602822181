<template>
    <b-card no-body class="m-2">
        <div class="m-2" align="center">
            <h2>venue</h2>
        </div>

        <b-overlay :show="show" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">

            <b-card-body>
                <div class="d-flex justify-content-between flex-wrap">

                    <b-form-group label="Filter">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group>

                    </b-form-group>
                </div>
            </b-card-body>

            <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
                :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                        @click="show_data(data.item)">
                        ดูเชิงลึก
                    </b-button>
                </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1">
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <div>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
                        prev-class="prev-item" next-class="next-item" class="mb-0">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </b-card-body>
            <template #overlay>
                <div class="text-center p-3 bg-primary text-light rounded">

                    <div class="mb-2">
                        <h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4rem" height="4rem" viewBox="0 0 24 24">
                                <circle cx="12" cy="2" r="0" fill="currentColor">
                                    <animate attributeName="r" begin="0" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(45 12 12)">
                                    <animate attributeName="r" begin="0.125s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(90 12 12)">
                                    <animate attributeName="r" begin="0.25s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(135 12 12)">
                                    <animate attributeName="r" begin="0.375s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(180 12 12)">
                                    <animate attributeName="r" begin="0.5s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(225 12 12)">
                                    <animate attributeName="r" begin="0.625s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(270 12 12)">
                                    <animate attributeName="r" begin="0.75s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(315 12 12)">
                                    <animate attributeName="r" begin="0.875s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                            </svg>

                            <br>
                            <br>
                            กำลังโหลด...
                        </h3>
                    </div>

                </div>

            </template>

        </b-overlay>
    </b-card>
</template>
  
<script>
import {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BMedia,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BOverlay,
} from "bootstrap-vue";
import api from "@/api";
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BCard,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BMedia,
        BDropdown,
        BDropdownItem,
        BOverlay
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            show: true,
            perPage: 50,
            pageOptions: [50, 100, 150, 300],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            fields: [
                {
                    key: "id",
                    label: "id",
                },
                { key: "title", label: "description", sortable: true },
                { key: "user", label: "user", sortable: true },
                { key: "gateway", label: "Gateway", sortable: true },
                { key: "aw_online", label: "Gateway Online", sortable: true },
                { key: "aw_offline", label: "Gateway Offline", sortable: true },
                { key: "ap", label: "AP", sortable: true },
                { key: "adddate", label: "adddate", sortable: true },
                { key: "province", label: "province", sortable: true },
                { key: "actions" },

            ],
            /* eslint-disable global-require */
            items: [],
            /* eslint-disable global-require */
            status: [
                {
                    1: "online",
                    2: "offline",
                },
                {
                    1: "light-success",
                    2: "light-danger",
                },
            ],
        };
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => ({ text: f.label, value: f.key }));
        },
    },
    mounted() {
        // Set the initial number of items
        this.loaddata();
        // this.totalRows = this.items.length
    },
    methods: {

        show_data(item) {
            console.log("show_data", item);
            localStorage.setItem("venue_id", item.id);
            localStorage.setItem("venue_title", item.title);
            this.$router.push({ path: "/reports" });


        },


        loaddata() {
            const params = {
                venue_id: localStorage.getItem("venue"),
            };
            api
                .post("/reports2", params)
                .then((response) => {
                    console.log(response.data.data);

                    this.items = response.data.data;
                    this.totalRows = response.data.total;
                    this.show = false
                })
                .catch((error) => {
                    // router.push('/pages/not-authorized');
                });
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`;
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  